/* eslint-disable no-unused-vars */

import KJUR from "jsrsasign";

const ONE_CLUB_API_DOMAIN = "https://api.nani.cool/dev/members";

const getTokenValidUrlForCookie = ONE_CLUB_API_DOMAIN + "/tokenValid";
const urlSigninforthirdparty = ONE_CLUB_API_DOMAIN + "/signinforthirdparty";
const getUserProfileUrl = ONE_CLUB_API_DOMAIN + "/getUserProfile";

const cookieLoginName = "nani_oneclass_login_token";

//checkNaniOneClass 確認登入狀況
//sendData 輸入帳密用
//logoutNaniOneClass 登出用

export const sendData = async (username, password) => {
	const isAccount = checkAccount(username);
	const isPass = checkPass(password);

	if (!isAccount.code) {
		return isAccount;
	}

	if (!isPass.code) {
		return isPass;
	}

	const dataJson = JSON.stringify({
		username: username,
		password: password,
		from: "Nani",
	});

	return fetch(urlSigninforthirdparty, {
		method: "POST",
		// headers 加入 json 格式
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			// 'Authorization': 'Basic ' + AuthorizationCode
		},
		body: dataJson,
	})
		.then((response) => {
			if (!response.ok) throw new Error(response.statusText);
			return response.json();
		})
		.then((data) => {
			//能進來應該已經是對的 response.ok
			if (data) {
				if (data.code === "SUCCESS") {
					if (data.jwt) {
						const jws = new KJUR.jws.JWS();
						jws.parseJWS(data.jwt);
						const hed = jws.parsedJWS.headS;
						const dte = JSON.parse(hed);
						if (jws.parsedJWS.payloadS) {
							const payloads = JSON.parse(jws.parsedJWS.payloadS);

							//暫時不管控emailvalid
							//   if (payloads.emailvalid) {
							saveTokenData(data);
							return { code: "SUCCESS", jwt: checkNaniLinkLogin() };
							//   } else {
							//     return {
							//       code: "FAILED",
							//       message:
							//         "登入失敗 Email尚未驗證，您的Email為 " + payloads.email
							//     };
							//   }
						} else {
							return { code: "FAILED", message: "登入失敗 Q_Q 資料異常" };
						}
					} else {
						return {
							code: "FAILED",
							message: "登入失敗 Q_Q 登入資料JWT發生異常",
						};
					}
				} else {
					// code: "FAILED"

					return { code: "FAILED", message: "帳號不存在或密碼錯誤" };
				}
			} else {
				return { code: "FAILED", message: "無資料" };
			}
		})
		.catch((err) => {
			return { code: "FAILED", message: "登入失敗 發生異常錯誤" };
		});
};

function saveTokenData(token) {
	if (typeof token == "object") {
		setNaniOneClassCookie(cookieLoginName, JSON.stringify(token));
		// localStorage["signInUserSessionForToken"] = JSON.stringify(token);
	} else if (typeof token == "string") {
		setNaniOneClassCookie(cookieLoginName, token);
		// localStorage["signInUserSessionForToken"] = token;
	}
}

export const logoutNaniOneClass = () => {
	deleteNaniOneClassCookie(cookieLoginName);
};

export const getProfile = async (params) => {
	return await fetch(getUserProfileUrl, {
		method: "POST",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
		body: JSON.stringify(params),
	}).then((res) => {
		return res.json();
	});
};

export const checkNaniLinkLogin = () => {
	const gettoken = getNaniOneClassCookie(cookieLoginName);
	if (gettoken) {
		return gettoken;
	}
	return "";
};

function getNaniOneClassCookie(name) {
	const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
	return v ? v[2] : null;
}

function setNaniOneClassCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	const expires = "expires=" + d.toUTCString();

	const hostname = window.location.hostname;
	if (hostname.indexOf("oneclass.com.tw") > 0) {
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=oneclass.com.tw";
	} else {
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
}

const deleteNaniOneClassCookie = function (name) {
	const hostname = window.location.hostname;
	if (hostname.indexOf("oneclass.com.tw") > 0) {
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=oneclass.com.tw";
	} else {
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
	}
};

function checkAccount(account) {
	const returnObj = { code: true, message: "" };

	const reAccount = new RegExp(/^[A-Za-z0-9]+$/);
	if (account.replace(/(^\s*)|(\s*$)/g, "").length === 0) {
		returnObj["code"] = "FAILED";
		returnObj["message"] = "請輸入會員帳號";
	}

	return returnObj;
}

function checkPass(password) {
	const returnObj = { code: true, message: "" };

	const re = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,12}$/);
	if (password.replace(/(^\s*)|(\s*$)/g, "").length === 0) {
		returnObj["code"] = "FAILED";
		returnObj["message"] = "請輸入密碼";
	}

	return returnObj;
}

export const checkNaniOneClass = async (callback) => {
	const checkToken = checkNaniLinkLogin();

	if (checkToken) {
		try {
			const jsonObj = JSON.parse(checkToken);
			if (jsonObj.jwt) {
				checkTokenValidForCookie(jsonObj.jwt, callback);
			} else {
				callback("");
			}
		} catch (error) {
			callback("");
		}
	} else {
		callback("");
	}
};

function checkTokenValidForCookie(nanitoken, callback) {
	const dataJson = JSON.stringify(
		// eslint-disable-next-line no-unexpected-multiline
		{
			jwt: nanitoken,
		},
	);
	fetch(getTokenValidUrlForCookie, {
		method: "POST",
		// headers 加入 json 格式
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			// 'Authorization': 'Basic ' + AuthorizationCode
		},
		body: dataJson,
	})
		.then((response) => {
			if (!response.ok) throw new Error(response.statusText);
			return response.json();
		})
		.then((jsonData) => {
			const newdate = new Date().getTime();

			if (jsonData.jwt) {
				callback(jsonData.jwt);
			} else {
				logoutNaniOneClass(function myfunction(result) {
					callback("");
				});
			}
			//能進來應該已經是對的 response.ok
		})
		.catch((err) => {
			logoutNaniOneClass(function myfunction(result) {
				callback("");
			});
		});
}
