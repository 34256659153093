/* eslint-disable no-console */
import axios from "axios";
import { checkNaniLinkLogin } from "services/oneClub";

axios.default.timeout = 20000;
// axios.defaults.withCredentials = true;

const parseEndpoint = (endpoint) => {
	const url = endpoint.indexOf("http") === 0 ? endpoint : process.env.REACT_APP_DOMAIN + endpoint;
	return url;
};

const formatParams = (data) => {
	Object.entries(data).forEach(([key, value]) => {
		if (value === "" || value.length === 0) {
			delete data[key];
		}
	});
	return data;
};

axios.interceptors.request.use(
	(config) => {
		config.headers = {
			"Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
			Authorization: checkNaniLinkLogin()
				? JSON.parse(checkNaniLinkLogin()).jwt
				: process.env.REACT_APP_RESOURCE_AUTHORIZATION,
			"Content-Type": "application/json",
			...config.headers,
		};

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

axios.interceptors.response.use(
	(response) => {
		if (!response.state === "success") Promise.reject(response.message);
		return response;
	},
	(err) => {
		if (err && err.response) {
			switch (err.response.status) {
				case 401:
					err.response = "Token 過期,請重新登入";
					console.log("token怪怪");
					break;
				case 404:
					err.response = `${err.response.status}:敏ＣＡ夢  995!!`;
					console.log("沒有這個拉！");
					break;
				case 500:
					err.response = `${err.response.status}:敏ＣＡ夢  995!!`;
					console.log("伺服器錯了哦！");
					break;
				case 503:
					err.response = `${err.response.status}:敏ＣＡ夢  995!!`;
					console.log("不想服務拉！");
					break;
				default:
					err.response = `${err.response.status}:敏ＣＡ夢  995!!`;
					console.log(`連結錯誤${err.response.status}`);
					break;
			}
		} else {
			err.response = "敏ＣＡ夢  995!!";
			console.log("連結錯誤", err.response);
		}
		return Promise.reject(err.response);
	},
);

export const fetch = (url, params = {}, config) => {
	return new Promise((resolve, reject) => {
		axios
			.get(url, {
				params: formatParams(params),
				...config,
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const post = (url, data = {}, config) => {
	return new Promise((resolve, reject) => {
		axios.post(url, formatParams(data), config).then(
			(response) => {
				resolve(response.data);
			},
			(err) => {
				reject(err);
			},
		);
	});
};

export const remove = (url, data = {}) => {
	return new Promise((resolve, reject) => {
		axios.delete(url, formatParams(data)).then(
			(response) => {
				resolve(response.data);
			},
			(err) => {
				reject(err);
			},
		);
	});
};

export const put = (url, data = {}) => {
	return new Promise((resolve, reject) => {
		axios.put(url, data).then(
			(response) => {
				resolve(response.data);
			},
			(err) => {
				reject(err);
			},
		);
	});
};

const api = {};

api.create = () => ({
	get: (url, params, config) => fetch(parseEndpoint(url), params, config),
	post: (url, data, config) => post(parseEndpoint(url), data, config),
	put: (url, data) => put(parseEndpoint(url), data),
	delete: (url, params) => remove(parseEndpoint(url), params),
});

export const Api = api.create();

export default Api;
