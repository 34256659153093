/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useMachine } from "@xstate/react";
import { fetchMachine, FetchEventType, FetchStates } from "machines/fetchMachine";
import { Tree, Modal } from "antd";
import { stringify } from "query-string";
import { Box } from "components";
import { setSelectOptions } from "utils/common";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { getChapter, getBookSelection } from "api/open";
import { addQuiz } from "api/quiz";
import {
	UiHomePage,
	UiNumber,
	UiSelectBtn,
	UiSelectList,
	UiBtnZone,
	UiFlexBox,
	UiButton,
	UiHint,
	UiFooter,
} from "./HomePage.style";

/**
 * 試題入庫
 */
const { DirectoryTree } = Tree;

const initSendParams = {
	year: "109",
	version: "N",
};

const env_link = {
	dev: "https://oneexam-dev.oneclass.com.tw/quiz/",
	uat: "https://oneexam-uat.oneclass.com.tw/quiz/",
	release: "https://oneexam.oneclass.com.tw/quiz/",
};

const hiddenChapter = {
	ECH: {
		B02: 13,
		B04: 15,
		B06: 15,
		B08: 15,
		B10: 15,
		B12: 12,
	},
	JPC: {
		B02: 13,
		B04: 14,
	},
	JTC: {
		B02: 4,
		B04: 3,
	},
};

export const HomePage = () => {
	const initUrlParams = queryString.parse(useLocation().search);
	const [sendParams, setSendParams] = useState(initSendParams);
	const [stateChapter, setChapter] = useState("");
	const [showHint, setShowHint] = useState(true);

	const btnSelect = (key, val) => {
		const nextData = {
			...sendParams,
			[key]: val,
		};
		if (key === "education") {
			delete nextData.subject;
			delete nextData.book;
		}
		if (key === "subject") {
			delete nextData.book;
		}
		setSendParams(nextData);
	};
	const onTreeSelect = (keys, info) => {
		if (!info.node?.isLeaf) return;
		setChapter(keys[0]);
	};

	const showSuccessModal = (data) => {
		const { content } = data;

		Modal.success({
			content: `${content}`,
			onOk: () => {
				window.close();
			},
		});
	};

	const showErrorModal = (data) => {
		const { content } = data;

		Modal.error({
			content: `${content}`,
			onOk: () => {},
		});
	};

	const sendDataToQuiz = async (params) => {
		try {
			const result = await addQuiz(params);
			const { status } = result;
			if (status !== "success") throw result;
			showSuccessModal(result);
		} catch (error) {
			showErrorModal(error);
		}
	};

	const goToQQ = () => {
		const { year, version, book, subject, education } = sendParams;
		const { roomId, teacherId, teacherName } = initUrlParams;
		const subjectName = subjectOptions.find((item) => item.value === subject).name;
		let qqParams = {
			year,
			version,
			education,
			subject: `${subject}_${subjectName}`,
			book,
			chapter: `CH${stateChapter}`,
		};
		if (roomId && teacherId && teacherName) {
			qqParams = { ...qqParams, ...initUrlParams };
			sendDataToQuiz(qqParams);
		} else {
			const querystring = stringify(qqParams);
			window.open(`${env_link[process.env.REACT_APP_NODE_ENV]}?${querystring}`);
		}
	};

	const formateLevelTreeData = (data, bookInfo) => {
		const childrenData = data.filter((item) => item.hierarchy === 2);
		let parentData = data.filter((item) => item.hierarchy === 1);
		if (bookInfo) {
			const { eduSubject, book } = bookInfo;
			if (eduSubject in hiddenChapter) {
				const hiddenCondition = hiddenChapter[eduSubject][book] ? hiddenChapter[eduSubject][book] : 99;
				parentData = parentData.filter((item) => item.code < hiddenCondition);
			}
		}
		return parentData.map((item) => {
			if (bookInfo && bookInfo.subject === "BI" && bookInfo.education === "J" && item.code === "6") {
				return {
					title: `${item.code}_${item.name}`,
					key: item.code,
					isLeaf: true,
				};
			} else {
				return {
					title: `${item.code}_${item.name}`,
					key: item.code,
					selectable: false,
					children: formateSingleTreeData(childrenData.filter((element) => element.parentCode === item.code)),
				};
			}
		});
	};

	const formateSingleTreeData = (data, bookInfo) => {
		let nextData = data;
		if (bookInfo) {
			const { eduSubject, book } = bookInfo;
			if (eduSubject in hiddenChapter) {
				const hiddenCondition = hiddenChapter[eduSubject][book] ? hiddenChapter[eduSubject][book] : 99;
				nextData = nextData.filter((item) => item.code < hiddenCondition);
			}
		}

		return nextData.map((item) => ({
			title: `${item.code}_${item.name}`,
			key: item.code,
			isLeaf: true,
		}));
	};

	const formateTreeDataFactory = (data, hierarchy, bookInfo) => {
		if (!data) return [];
		let nextData = [];
		switch (hierarchy) {
			case 2:
				nextData = formateLevelTreeData(data, bookInfo);
				break;
			case 1:
			default:
				nextData = formateSingleTreeData(data, bookInfo);
				break;
		}
		return nextData;
	};

	const [stateBookSelection, sendBookSelection] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_, event) => {
				const { year } = event;
				const result = await getBookSelection(year);
				const {
					isSuccess,
					message,
					data: { bookMap, eduMap, subjectMap, versionMap, yearMap },
				} = result;
				return {
					isSuccess,
					message,
					bookMap,
					eduMap,
					subjectMap,
					versionMap: setSelectOptions(versionMap),
					yearMap: setSelectOptions(yearMap),
				};
			},
		},
	});

	const [stateGetChapter, sendGetChapter] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_, event) => {
				const { bookID } = event;
				const result = await getChapter(bookID);
				const {
					isSuccess,
					message,
					data: { chapters, bookInfo },
				} = result;
				let maxHierarchy = 0;
				for (const item of chapters) {
					if (item.hierarchy > maxHierarchy) maxHierarchy = item.hierarchy;
				}
				return {
					isSuccess,
					message,
					chapters,
					maxHierarchy,
					bookInfo,
				};
			},
		},
	});

	const { bookMap, eduMap, subjectMap, yearMap } = stateBookSelection.context.result || {};
	const { chapters, maxHierarchy, bookInfo } = stateGetChapter.context.result || {};
	const eduOptions = useMemo(() => {
		if (!sendParams.year || !sendParams.version || !eduMap) return [];
		return setSelectOptions(eduMap[`${sendParams.year}${sendParams.version}`]).filter((item) => item.value !== "H");
	}, [sendParams.year, sendParams.version, bookMap]);

	const subjectOptions = useMemo(() => {
		if (!sendParams.year || !sendParams.version || !sendParams.education || !subjectMap) return [];
		return setSelectOptions(subjectMap[`${sendParams.year}${sendParams.version}-${sendParams.education}`]);
	}, [sendParams.year, sendParams.version, sendParams.education, subjectMap]);

	const bookOptions = useMemo(() => {
		if (!sendParams.year || !sendParams.version || !sendParams.education || !sendParams.subject || !bookMap) return [];
		return setSelectOptions(
			bookMap[`${sendParams.year}${sendParams.version}-${sendParams.education}${sendParams.subject}`],
		)
			.filter((item) => item.value !== "B00")
			.filter((item) => parseInt(item.value.split("B")[1]) % 2 === 0);
	}, [sendParams.year, sendParams.version, sendParams.education, sendParams.subject, bookMap]);

	const chaptersData = useMemo(() => {
		const { year, version, book, subject, education } = sendParams;
		if (!year || !version || !book || !subject || !education || !stateGetChapter.matches(FetchStates.Resolved))
			return [];
		return formateTreeDataFactory(chapters, maxHierarchy, bookInfo);
	}, [
		sendParams.year,
		sendParams.version,
		sendParams.education,
		sendParams.subject,
		sendParams.book,
		chapters,
		maxHierarchy,
		bookInfo,
		stateGetChapter.value,
	]);

	const btnDisabled = useMemo(() => {
		const { year, version, book, subject, education } = sendParams;
		if (!year || !version || !book || !subject || !education || !stateChapter) return true;
		return false;
	}, [sendParams.year, sendParams.version, sendParams.education, sendParams.subject, sendParams.book, stateChapter]);

	useEffect(() => {
		const { year } = sendParams;
		sendBookSelection(FetchEventType.Fetch, {
			year,
		});
	}, []);
	useEffect(() => {
		const { year, version, book, subject, education } = sendParams;
		if (!year || !version || !book || !subject || !education) return;
		setChapter("");
		sendGetChapter(FetchEventType.Fetch, {
			bookID: `${year}${version}-${education}${subject}${book}`,
		});
	}, [sendParams]);
	return (
		<>
			{showHint ? (
				<UiHint>
					<div>
						<div>使用電腦及平板 以獲得較佳的使用者體驗</div>
						<div>防疫題庫目前僅支援國中小使用</div>
					</div>
					<i
						className="material-icons"
						style={{ fontSize: "14px" }}
						onClick={() => {
							setShowHint(false);
						}}>
						close
					</i>
				</UiHint>
			) : undefined}

			<UiHomePage showhint={showHint}>
				<UiFlexBox>
					<Box>
						<div className="label">
							<UiNumber opacity={0.2}>1</UiNumber>
							學年度
						</div>
						<UiSelectList>
							{yearMap &&
								yearMap.map((item, index) => {
									if (item.value !== "109") return null;
									return (
										<>
											<UiSelectBtn
												key={`${index}${item.value}`}
												active={sendParams.year === item.value}
												onClick={() => {
													btnSelect("year", item.value);
												}}>
												{item.name}
											</UiSelectBtn>
										</>
									);
								})}
						</UiSelectList>
					</Box>
					<Box>
						<div className="label">
							<UiNumber opacity={0.4}>2</UiNumber>
							學制
						</div>
						<UiSelectList>
							{eduOptions &&
								eduOptions.map((item, index) => (
									<>
										<UiSelectBtn
											key={`${index}${item.value}`}
											active={sendParams.education === item.value}
											onClick={() => {
												btnSelect("education", item.value);
											}}>
											{item.name}
										</UiSelectBtn>
									</>
								))}
						</UiSelectList>
					</Box>
					{subjectOptions && subjectOptions.length > 0 ? (
						<Box>
							<div className="label">
								<UiNumber opacity={0.6}>3</UiNumber>
								科目
							</div>
							<UiSelectList>
								{subjectOptions.map((item, index) => (
									<>
										<UiSelectBtn
											key={`${index}${item.value}`}
											active={sendParams.subject === item.value}
											onClick={() => {
												btnSelect("subject", item.value);
											}}>
											{item.name}
										</UiSelectBtn>
									</>
								))}
							</UiSelectList>
						</Box>
					) : undefined}
					{bookOptions.length > 0 ? (
						<Box>
							<div className="label">
								<UiNumber opacity={0.8}>4</UiNumber>
								冊次
							</div>
							<UiSelectList>
								{bookOptions.map((item, index) => (
									<>
										<UiSelectBtn
											key={`${index}${item.value}`}
											active={sendParams.book === item.value}
											onClick={() => {
												btnSelect("book", item.value);
											}}>
											{item.name}
										</UiSelectBtn>
									</>
								))}
							</UiSelectList>
						</Box>
					) : undefined}
				</UiFlexBox>
				<UiFlexBox>
					{chaptersData.length > 0 ? (
						<Box>
							<div className="label">
								<UiNumber opacity={1}>5</UiNumber>
								冊次
							</div>
							<UiSelectList>
								<DirectoryTree
									showIcon={false}
									defaultSelectedKeys={[]}
									treeData={chaptersData}
									onSelect={onTreeSelect}
								/>
							</UiSelectList>
						</Box>
					) : undefined}
				</UiFlexBox>
			</UiHomePage>
			<UiBtnZone>
				<UiButton disabled={btnDisabled} variant="green" onClick={goToQQ}>
					送出
				</UiButton>
			</UiBtnZone>
			<UiFooter>Made with Copyright © NANI ENTERPRISE CO.,LTD. All Rights Reserved.</UiFooter>
		</>
	);
};
