import { HomePage } from "views";

import { MainLayout } from "layouts";

const routes = [
	{
		path: process.env.PUBLIC_URL + "/",
		component: MainLayout,
		exact: true,
		routes: [
			{
				path: "/",
				component: HomePage,
				name: "home",
				exact: true,
			},
		],
	},
];
export default routes;
