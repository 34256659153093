/* eslint-disable react/prop-types */
import React from "react";
import { useHistory } from "react-router-dom";
import RenderRoutes from "routes/RenderRoutes";
import { UiMainLayout, UiMainLayoutHeader } from "./MainLayout.style";

/**
 * 試題入庫
 */

export const MainLayout = ({ routes = [] }) => {
	const history = useHistory();
	return (
		<UiMainLayout>
			<div className="header">
				<UiMainLayoutHeader>
					<h1 className="title" onClick={() => history.push("/")}>
						OneClass防疫題庫-<span>範圍選擇</span>
					</h1>
				</UiMainLayoutHeader>
			</div>
			<div className="container">
				<div className="content">
					<div className="scroll">
						<RenderRoutes routes={routes} />
					</div>
				</div>
			</div>
		</UiMainLayout>
	);
};
