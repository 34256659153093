import styled from "styled-components";

export const UiMainLayout = styled.div`
	position: relative;
	overflow: hidden;
	height: 100vh;

	& > .header {
		display: flex;
		align-items: center;
		width: 100%;
		height: 54px;
		background-color: #ec7963;
	}

	& > .container {
		position: relative;
		display: flex;
		flex: 1;

		& > .content {
			display: flex;
			width: 100%;
			height: calc(100vh - 54px);
			transition: 0.35s;
			flex: 1;
			flex-direction: column;

			& > .scroll {
				overflow: scroll;
				height: 100%;
			}
		}
	}
`;

export const UiMainLayoutHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px;
	color: #fff;
	flex: 1;

	.title {
		display: flex;
		align-items: center;
		margin: 0;
		font-size: 24px;
		color: #fff;
		cursor: pointer;

		& > span {
			display: flex;
			font-size: 16px;
		}
	}
`;
