import axios from "axios";

export const deleteAllCookies = () => {
	const cookies = document.cookie.split(";");
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i];
		const eqPos = cookie.indexOf("=");
		const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		const hostname = window.location.hostname;
		if (hostname.indexOf("oneclass.com.tw") > 0) {
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=oneclass.com.tw";
		} else {
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
		}
	}
};
const getCookie = (name) => {
	const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
	return v ? v[2] : null;
};
export const checkLogin = (cookieLoginName) => {
	const gettoken = getCookie(cookieLoginName);
	if (gettoken) {
		return gettoken;
	}
	return "";
};
export const setCookie = (cname, cvalue, exdays) => {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	const expires = "expires=" + d.toUTCString();

	const hostname = window.location.hostname;
	if (hostname.indexOf("oneclass.com.tw") > 0) {
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=oneclass.com.tw";
	} else {
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
};

export const exportFile = async (url, method, payload) => {
	axios({ url, method: method || "get", data: payload, responseType: "blob" }).then((res) => {
		const fileName = decodeURI(res.headers["content-disposition"].replace(/\w+;filename=(.*)/, "$1")).split(
			"UTF-8''",
		)[1];
		const url = window.URL.createObjectURL(new Blob([res.data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", fileName);
		document.body.appendChild(link);
		link.click();
	});
};

export const setSelectOptions = (data, needNull = false) => {
	if (!data) return [];
	const result =
		data.length > 0
			? data.map((item) => {
					return { name: item.name, value: item.code };
			  })
			: [];

	if (needNull) {
		result.push({
			name: "---",
			value: "",
		});
	}
	return result;
};
