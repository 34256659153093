import { Api } from "api";
import { stringify } from "query-string";
/**
 * 防疫題庫 基礎選項
 */
export const getBasicSelection = async () => {
	const querystring = stringify({
		mergeSchoolYear: true,
	});
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Open/BasicSelection?${querystring}`);
	return response;
};

export const getBookSelection = async (year, payload) => {
	// const querystring = stringify(payload);
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Open/${year}/BookSelection`);
	return response;
};

export const getChapter = async (bookID) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Open/${bookID}/Chapter`);
	return response;
};
