import React from "react";

export default function useGoogleAnalytics() {
	React.useEffect(() => {
		const gaSourceScript = document.createElement("script");
		gaSourceScript.async = true;
		gaSourceScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA}`;
		document.body.appendChild(gaSourceScript);

		const script = document.createElement("script");
		script.async = true;
		script.text = `
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());

			gtag('config', '${process.env.REACT_APP_GA}');
		`;
		document.body.appendChild(script);
	}, []);
}
