import styled from "styled-components";
import { variant, space } from "styled-system";
import { Button as AntButton } from "antd";

export const UiHomePage = styled.div`
	position: relative;
	display: block;
	display: flex;
	overflow: auto;
	margin: 12px 8px;
	margin-top: ${({ showhint }) => (showhint ? "32px !important" : "12px !important")};
	max-height: calc(100vh - 240px);
	border: 1px solid #d5d7de;
	border-radius: 4px;

	> div {
		height: 100%;
	}

	.label {
		display: flex;
		align-items: center;
		font-size: 24px;
	}

	@media screen and (min-width: 640px) {
		margin: 12px 16px;
	}
`;
export const UiNumber = styled.span`
	font-size: 48px;
	color: #3a4052;
	opacity: ${({ opacity }) => opacity || "1"};
`;

export const UiSelectBtn = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 8px 0;
	margin-right: 4px;
	padding: 9px 16px;
	color: ${({ active }) => (active ? "#fff" : "#121232")};
	background: ${({ active }) => (active ? "#121232" : "#fff")};
	border: 1px solid #d5d7de;
	border-radius: 4px;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
	flex-direction: column;
	box-sizing: border-box;
	flex: none;
	order: 0;
	cursor: pointer;
`;
export const UiFlexBox = styled.div`
	display: flex;
	flex: 1;
	padding: 8px;
	flex-direction: column;

	&:first-child {
		border-right: 1px solid #d5d7de;
	}
`;
export const UiSelectList = styled.div`
	.ant-tree {
		.ant-tree-node-content-wrapper {
			&.ant-tree-node-selected {
				color: #fff;
				background-color: #121232;
			}
		}

		&.ant-tree-directory {
			.ant-tree-treenode-selected {
				&::before {
					color: #fff;
					background-color: #121232;
				}
			}
		}
	}
`;
export const UiBtnZone = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
`;

export const UiButton = styled(AntButton)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${(props) => (props.full ? "100%" : "max-content")};

	> .icon {
		display: flex;
		margin-right: 8px;

		> div {
			vertical-align: middle;
		}
	}
	${space}
	${variant({
		variants: {
			green: {
				color: "#fff",
				background: "#4C99A5",
				borderColor: "#4C99A5",
			},
		},
	})}

	&:hover,
	&:focus,
	&:active {
		opacity: 0.8;
		${variant({
			variants: {
				green: {
					color: "#fff",
					background: "#4C99A5",
					borderColor: "#4C99A5",
				},
			},
		})}
	}
`;

export const UiHint = styled.div`
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 6px 10px;
	width: 100%;
	color: #fff;
	background: #a1a4b1;
	z-index: 999;

	& > i {
		cursor: pointer;
	}
`;

export const UiFooter = styled.div`
	position: fixed;
	bottom: 0;
	display: flex;
	justify-content: center;
	padding: 16px 8px;
	width: 100%;
	color: #fff;
	background-color: #a1a4b1;
`;
