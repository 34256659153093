import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import PropTypes from "prop-types";

const theme = {
	colors: {
		white: "#fff",
		lightblue: "#82b4ff",
		dark: "#1f1f1f",
		gray: "#333333",
		buttonColor: {
			orange: "#ffb800",
			deepOrange: "#ff6b18",
			blue: "#1890ff",
			green: "#4caf50",
			yellow: "#ffec43",
			red: "#ff4d4f",
			gray: "#B0B0B0",
		},
	},
	background: {
		buttonColor: {
			orange: "#ffb800",
			deepOrange: "#ff6b18",
			blue: "#1890ff",
			green: "#4caf50",
			yellow: "#ffec43",
			red: "#ff4d4f",
			white: "#fff",
			gray: "#B0B0B0",
		},
	},
	borderColor: {
		buttonColor: {
			orange: "#ffb800",
			deepOrange: "#ff6b18",
			blue: "#1890ff",
			green: "#4caf50",
			yellow: "#ffec43",
			red: "#ff4d4f",
			white: "#fff",
			gray: "#B0B0B0",
		},
	},
};

const ThemeProvider = ({ children }) => {
	return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

ThemeProvider.propTypes = {
	children: PropTypes.element,
};

export default ThemeProvider;
