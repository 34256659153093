import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
// import AuthProvider from "providers/AuthProvider";
import "material-icons-font/material-icons-font.css";
import ThemeProvider from "providers/ThemeProvider";
import "antd/dist/antd.css";
import "./index.css";
// const { Provider } = AuthProvider;
const root = document.getElementById("root");

const basename = process.env.NODE_ENV === "production" ? "/scope" : "/";
const Main = () => (
	<BrowserRouter basename={basename}>
		{/* <Provider> */}
		<ThemeProvider>
			<App />
		</ThemeProvider>
		{/* </Provider> */}
	</BrowserRouter>
);

ReactDOM.render(Main(), root);

reportWebVitals();
